<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="568px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
	 <div class="aggree">
		 <div class="agree-title">用户协议</div>
		 <div class="agree-content">
			 一、注册条款的接受
			 依据《网络安全法》《互联网用户账号信息管理规定》等法律法规的要求，您在注册页面完成手机短信验证并勾选同意《用户协议》等相关协议，即表示您已经阅读并且同意与公司达成本协议，成为的用户，并接受本协议中的全部注册条款以及《隐私政策》和本平台内公布的包括但不限于《职位信息发布规则》《增值服务协议》等各项协议或规则（统称“平台协议”或“平台规则”）的所有条款的约束，包括争议解决条款（详见本协议第十五条）。
			 
			 二、用户的注册与认证
			 申请注册账号的用户应同时满足下列全部条件：在注册之日以及此后使用服务期间必须以招聘和/或求职为目的；在注册之日必须年满16周岁以上。
			 为了更好地享有聘平台服务，用户应遵守聘册机制的要求，向提供本人真实、准确、最新及完整的资料；注册并认证成为招聘者的用户（包括来自用人单位和人力资源服务机构的用户），应保证及时更新本人提供的单位名称、《人力资源许可证》或/和《劳务派遣许可证》、职务或岗位信息、企业邮箱、招聘授权书、承诺函等相关信息及证明材料，并确保前述信息及证明材料的真实性；用户应保证其职务行为，包括但不限于发布招聘信息、与求职者沟通、获取、使用、转发求职者简历等均在使用本平台期间持续有效；通过认证的用户应保持其招聘账号与对应的授权单位具有唯一性。
			一、注册条款的接受
			 依据《网络安全法》《互联网用户账号信息管理规定》等法律法规的要求，您在注册页面完成手机短信验证并勾选同意《用户协议》等相关协议，即表示您已经阅读并且同意与公司达成本协议，成为的用户，并接受本协议中的全部注册条款以及《隐私政策》和本平台内公布的包括但不限于《职位信息发布规则》《增值服务协议》等各项协议或规则（统称“平台协议”或“平台规则”）的所有条款的约束，包括争议解决条款（详见本协议第十五条）。
			 
			 二、用户的注册与认证
			 申请注册账号的用户应同时满足下列全部条件：在注册之日以及此后使用服务期间必须以招聘和/或求职为目的；在注册之日必须年满16周岁以上。
			 为了更好地享有聘平台服务，用户应遵守聘册机制的要求，向提供本人真实、准确、最新及完整的资料；注册并认证成为招聘者的用户（包括来自用人单位和人力资源服务机构的用户），应保证及时更新本人提供的单位名称、《人力资源许可证》或/和《劳务派遣许可证》、职务或岗位信息、企业邮箱、招聘授权书、承诺函等相关信息及证明材料，并确保前述信息及证明材料的真实性；用户应保证其职务行为，包括但不限于发布招聘信息、与求职者沟通、获取、使用、转发求职者简历等均在使用本平台期间持续有效；通过认证的用户应保持其招聘账号与对应的授权单位具有唯一性。

		 </div>
	 </div>
	  <span slot="footer" class="dialog-footer">
		  <div class="dialog-footer-button" @click="dialogVisible=false">阅读并同意</div>
	  </span>
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return {
				dialogVisible:false,
				name:""
			}
		},
		methods:{
			init(){
				this.dialogVisible = true;
			}
		}
	}
</script>


<style scoped lang="scss">
	.aggree{
		.agree-title{
			font-weight: 500;
			font-size: 16px;
			color: #3B3B3B;
			line-height: 22px;
			text-align: center;
		}
		.agree-content{
			font-weight: 400;
			font-size: 14px;
			color: #3B3B3B;
			line-height: 20px;
			padding: 20px 24px;
			box-sizing: border-box;
			height: 40vh;
			overflow: auto;
		}
	}
	.dialog-footer-button{
		width: 166px;
		height: 50px;
		background: #FFB80F;
		border-radius: 6px;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		margin: 0 auto;
		cursor: pointer;
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
	::v-deep .el-button{
		width: 80px;
		height: 30px;
		border-radius: 6px;
		border: 1px solid #3F81FF;
		line-height: 30px;
		padding: 0px;
		font-weight: 400;
		font-size: 12px;
		color: #3F81FF;
		text-align: center;
	}
	::v-deep .el-button--primary{
		color: #fff;
	}
	::v-deep .el-input__inner{
		width: 308px;
		height: 44px;
		background: #F9F9F9;
		border-radius: 6px;
		border: 0px;
	}
</style>