<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="3"></centerLeft>
			<div class="score-info">
				<img class="score-info-img" src="@/assets/images/me/score.png"/>
				<div class="score-info-title">做任务得积分</div>
				<div class="score-list">
					<div class="score-item flex-between flex-center">
						<div class="score-item-left">
							<div>每日留言(0/5)</div>
							<div>+10积分</div>
						</div>
						<div class="score-item-right-one">小程序中完成</div>
					</div>
					<div class="score-item flex-between flex-center">
						<div class="score-item-left">
							<div>视频评论（0/5）</div>
							<div>+10积分</div>
						</div>
						<div class="score-item-right-one">小程序中完成</div>
					</div>
					<div class="score-item flex-between flex-center">
						<div class="score-item-left">
							<div>加入社群</div>
							<div>+10积分</div>
						</div>
						<div class="score-item-right-two">去完成</div>
					</div>
					<div class="score-item flex-between flex-center">
						<div class="score-item-left">
							<div>邀请好友（10/100）</div>
							<div>+10积分</div>
						</div>
						<div class="score-item-right-three">已完成</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import infoTop from "@/components/info-top.vue"
	import centerLeft from "@/components/center-left.vue"
	export default {
		components:{
			infoTop,
			centerLeft
		},
		data(){
			return {
			}
		},
		methods:{
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
</style>