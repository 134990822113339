<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="504px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
	 <div class="wechatcode">
		 <div class="wechatcode-title">加入社群获得兑换码</div>
		 <div class="wechatcode-content">
			 <img src="@/assets/images/temp/1.png"/>
		 </div>
		 <el-input type="text" placeholder="输入兑换码获得积分"></el-input>
	 </div>
	  <span slot="footer" class="dialog-footer">
		  <div class="dialog-footer-button" @click="dialogVisible=false">提交</div>
	  </span>
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return {
				dialogVisible:false,
				name:""
			}
		},
		methods:{
			init(){
				this.dialogVisible = true;
			}
		}
	}
</script>


<style scoped lang="scss">
	.wechatcode{
		.wechatcode-title{
			font-weight: bold;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			text-align: center;
			padding: 10px 0px 24px;
		}
		.wechatcode-content{
			width: 144px;
			height: 144px;
			border: 6px solid #FFF6E2;
			border-radius: 10px;
			margin: 0px auto 20px;
			img{
				width: 144px;
				height: 144px;
			}
		}
	}
	.dialog-footer-button{
		width: 370px;
		height: 50px;
		background: #FFB80F;
		border-radius: 6px;
		cursor: pointer;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		margin: 16px auto 0px;
	}
	::v-deep .addModel{
		border-radius: 10px !important;
		padding-bottom: 38px;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
	
	::v-deep .el-input__inner{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		font-weight: 400;
		font-size: 16px;
		color: #D1D2D4;
		line-height: 50px;
		text-align: left;
		border: 0px;
		margin: 0 auto;
		display: block;
	}
</style>