<template>
	<div class="login">
		<div class="login-login">
			<div class="login-model">
				<div class="login-model-text">注册</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
					<el-form-item prop="account">
						<el-input v-model="ruleForm.account" placeholder="请输入您的手机号码"></el-input>
					</el-form-item>
					<el-form-item prop="account">
						<el-input v-model="ruleForm.account" placeholder="请输入验证码">
							<div slot="suffix" class="send-code">
								获取验证码
							</div>
						</el-input>
					</el-form-item>
					<el-form-item prop="account">
						<el-input v-model="ruleForm.account" placeholder="请输入邮箱"></el-input>
					</el-form-item>
					<el-form-item prop="account">
						<el-input v-model="ruleForm.account" placeholder="请输入验证码">
							<div slot="suffix" class="send-code">
								获取验证码
							</div>
						</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.password" placeholder="请输入密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.password" placeholder="请再次输入确认密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					<el-form-item>
						<div class="form-button">
							<div @click="tolast">取消</div>
							<div @click="submitForm('ruleForm')">下一步</div>
						</div>
					</el-form-item>
					<div class="agree">
						<img src="@/assets/images/noselect.png"/>
						<div>我已阅读并同意<a>《隐私政策》</a>和<a>《用户协议》</a></div>
					</div>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		data(){
			return {
				ruleForm:{
					account:"",
					password:""
				},
				rules:{
					account: [
						{ required: true, message: '请输入邮箱或手机号', trigger: 'blur' },
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' }
					]
				},
				showpwd:false,//是否展示密码
			}
		},
		methods:{
			submitForm(formName){
				
					this.$router.push("/next");
				// this.$refs[formName].validate((valid) => {
				// 	if (valid) {
				// 	} else {
				// 		return false;
				//   }
				// });
			},
			tolast(){
				this.$router.push("/login");
			},
			toRegister(){
				this.$router.push("/register");
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login{
		width: 100%;
		height: 100vh;
		background-image: url(../assets/images/login/bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.login-login{
		display: flex;
		justify-content: center;
		align-items: center;
		top: 50%;
		right: 15%;
		position: absolute;
		transform: translate(0%, -50%);
	}
	.login-model{
		width: 690px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 54px 0px 47px;
		.login-model-text{
			font-weight: bold;
			font-size: 24px;
			color: #16191E;
			line-height: 33px;
			text-align: center;
		}
	}
	.ruleForm{
		margin: 38px auto 0px;
		width: 370px;
		.pwd-see{
			width: 28px;
			height: 28px;
			position: absolute;
			top: 50%;
			margin-top: -14px;
			right: 18px;
			cursor: pointer;
		}
		.send-code{
			height: 22px;
			font-weight: 400;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			border-left: 1px solid #E3E3E3;
			width: 120px;
			text-align: center;
			position: absolute;
			top: 50%;
			right: 0px;
			margin-top: -11px;
		}
	}
	::v-deep .el-input__inner{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		border: 0px;
	}
	::v-deep .el-form-item{
		margin-bottom: 18px;
	}
	.login-button{
		font-weight: 400;
		font-size: 14px;
		color: #3C4658;
		line-height: 20px;
		margin-top: 13px;
		display: flex;
		justify-content: space-between;
		div{
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			color: #3C4658;
			line-height: 20px;
			text-align: right;
		}
	}
	.form-button{
		margin-top: 27px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		div{
			&:first-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #FFB80F;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
			&:last-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				background: #FFB80F;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #fff;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.wechat{
		text-align: center;
		margin-top: 43px;
		cursor: pointer;
		img{
			width: 55px;
			height: 55px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			margin-top: 10px;
		}
	}
	.agree{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 23px;
		cursor: pointer;
		img{
			width: 14px;
			height: 14px;
			margin-right: 8px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			a{
				color: #FFB80F;
			}
		}
	}
</style>