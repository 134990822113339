<template>
	<div class="info-top flex-between flex-center">
		<div class="info-top-left">个人中心</div>
		<div class="info-top-center flex-start flex-center">
			<img src="@/assets/images/laba.png"/>
			<div class="info-top-center-text">
				<div class="text flex-start">
					<span>滚动播报XXXXX热列庆祝2024年05月11日</span>
					<span>滚动播报XXXXX热列庆祝2024年05月11日</span>
					<span>滚动播报XXXXX热列庆祝2024年05月11日</span>
				</div>
			</div>
		</div>
		<div class="info-top-right" @click="toinfo">返回首页</div>
	</div>
</template>

<script>
	export default {
		props:{
			title:"",
			show:true
		},
		data(){
			return {
			}
		},
		methods:{
			toinfo(){
				this.$router.push("/");
			}
		}
	}
</script>
<style scoped lang="scss">
	.info-top{
		width: 1200px;
		padding: 15px 0px 18px;
		.info-top-left{
			font-weight: bold;
			font-size: 20px;
			color: #16191E;
			line-height: 28px;
		}
		.info-top-right{
			width: 88px;
			height: 26px;
			border-radius: 13px;
			border: 1px solid #D7D7D7;
			font-weight: 400;
			font-size: 16px;
			color: #777C85;
			line-height: 26px;
			text-align: center;
			cursor: pointer;
		}
		.info-top-center{
			width: 977px;
			height: 44px;
			background: #FFF8F0;
			border-radius: 29px;
			padding: 0px 17px;
			box-sizing: border-box;
			img{
				width: 20px;
				height: 21px;
				margin-right: 10px;
			}
			.info-top-center-text{
				white-space: nowrap;
				overflow: hidden;
				box-sizing: border-box;
				span{
					font-weight: 400;
					font-size: 14px;
					color: #16191E;
					line-height: 20px;
					margin-right: 48px;
				}
			}
		}
	}
</style>