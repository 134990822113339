import { render, staticRenderFns } from "./examinfo.vue?vue&type=template&id=0456421e&scoped=true"
import script from "./examinfo.vue?vue&type=script&lang=js"
export * from "./examinfo.vue?vue&type=script&lang=js"
import style0 from "./examinfo.vue?vue&type=style&index=0&id=0456421e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0456421e",
  null
  
)

export default component.exports