<template>
	<div class="content">
		<indexTop title="专项题库" :show="true"></indexTop>
		<div class="content-table">
			<div class="content-table-search">
				<div class="content-table-search-tab flex-start flex-center flex-wrap">
					<div class="active">EDXA-AL本土</div>
					<div>EDXA-AL本土</div>
					<div>EDXA-AL本土</div>
					<div>EDXA-AL本土</div>
				</div>
				<div class="content-table-search-tab-two flex-start flex-center flex-wrap">
					<div class="active">本土</div>
					<div>本土</div>
					<div>本土</div>
					<div>本土</div>
				</div>
			</div>
			
			 <el-table :data="tableData" style="width: 100%">
				<el-table-column type="selection" label="全选"></el-table-column>
				<el-table-column prop="date" label="真题试卷名称"></el-table-column>
				<el-table-column prop="date" label="考试局"></el-table-column>
				<el-table-column prop="date" label="科目"></el-table-column>
				<el-table-column prop="date" label="操作" width="200">
					<template slot-scope="scope">
						<div class="see-button flex-center flex-midden">
							<img src="@/assets/images/see.png"/>
							<div>查阅</div>
						</div>
					</template>
				</el-table-column>
			  </el-table>
			  <div class="table-bottom flex-between flex-center">
					  <div class="table-bottom-left flex-start flex-center">
						  <div>已选择 <span>xx</span> 条</div>
						  <div class="flex-midden flex-center">
							  <img src="@/assets/images/down.png"/>
							  <span>下载自组试卷</span>
						  </div>
					  </div>
				  
				  
					<div class="table-bottom-right">
						<el-pagination :current-page="page" :page-sizes="[10, 20, 30, 40, 50]" :page-size="10" layout="sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
			  </div>
		</div>
	</div>
</template>

<script>
	import indexTop from "@/components/index-top.vue"
	export default {
		components:{
			indexTop
		},
		data(){
			return {
				tableData:[
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"}
				],
				page:1,
				total:100
			}
		},
		methods:{
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
</style>