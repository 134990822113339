var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-left"},[_c('img',{staticClass:"center-left-setting",attrs:{"src":require("@/assets/images/setting.png")}}),_vm._m(0),_c('div',{staticClass:"center-left-menu"},[_c('router-link',{staticClass:"center-left-menu-item flex-start flex-center",class:_vm.menu==1?'active':'',attrs:{"to":"/center"}},[_c('span',{staticClass:"active-text"}),(_vm.menu == 1)?_c('img',{attrs:{"src":require("@/assets/images/me/11.png")}}):_c('img',{attrs:{"src":require("@/assets/images/me/1.png")}}),_c('div',[_vm._v("个人资料")])]),_vm._m(1),_c('router-link',{staticClass:"center-left-menu-item flex-start flex-center",class:_vm.menu==3?'active':'',attrs:{"to":"/center/score"}},[_c('span',{staticClass:"active-text"}),(_vm.menu == 3)?_c('img',{attrs:{"src":require("@/assets/images/me/33.png")}}):_c('img',{attrs:{"src":require("@/assets/images/me/3.png")}}),_c('div',[_vm._v("我的积分"),_c('span',[_vm._v("（1000积分）")])])]),_c('router-link',{staticClass:"center-left-menu-item flex-start flex-center",class:_vm.menu==4?'active':'',attrs:{"to":"/center/member"}},[_c('span',{staticClass:"active-text"}),(_vm.menu == 4)?_c('img',{attrs:{"src":require("@/assets/images/me/44.png")}}):_c('img',{attrs:{"src":require("@/assets/images/me/4.png")}}),_c('div',[_vm._v("我的会员")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)],1),_c('div',{staticClass:"user-left-quit"},[_vm._v("退出登录")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-left-user"},[_c('img',{attrs:{"src":require("@/assets/images/temp/1.png")}}),_c('div',[_vm._v("张美美")]),_c('div',[_vm._v("17149392242")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-left-menu-item flex-start flex-center"},[_c('img',{attrs:{"src":require("@/assets/images/me/2.png")}}),_c('div',[_vm._v("我的报告")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-left-menu-item flex-start flex-center"},[_c('img',{attrs:{"src":require("@/assets/images/me/5.png")}}),_c('div',[_vm._v("我的足迹")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-left-menu-item flex-start flex-center"},[_c('img',{attrs:{"src":require("@/assets/images/me/6.png")}}),_c('div',[_vm._v("错题集")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-left-menu-item flex-start flex-center"},[_c('img',{attrs:{"src":require("@/assets/images/me/7.png")}}),_c('div',[_vm._v("用户协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-left-menu-item flex-start flex-center"},[_c('img',{attrs:{"src":require("@/assets/images/me/8.png")}}),_c('div',[_vm._v("隐私协议")])])
}]

export { render, staticRenderFns }