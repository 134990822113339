import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import Login from '../views/Login.vue'
import index from '../views/index.vue'
import register from '../views/register.vue'
import next from '../views/next.vue'
import forget from '../views/forget.vue'
import bindphone from '../views/bindphone.vue'
import pastpaper from '../views/pastpaper.vue'
import question from '../views/question.vue'
import search from '../views/search.vue'
import answer from '../views/answer.vue'
import examinfo from '../views/examinfo.vue'
import centerindex from '../views/center/index.vue'
import centerscore from '../views/center/score.vue'
import centermemeber from '../views/center/member.vue'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/next',
    name: 'next',
    component: next
  },
  {
    path: '/forget',
    name: 'forget',
    component: forget
  },
  {
    path: '/bindphone',
    name: 'bindphone',
    component: bindphone
  },
  {
    path: '/',
    component: Layout,
    hidden: true,
    children: [
		{
			path: '/',
			name:"index",
			component: index
		},
		{
			path: '/pastpaper',
			name:"pastpaper",
			component: pastpaper
		},
		{
			path: '/question',
			name:"question",
			component: question
		},
		{
			path: '/search',
			name:"search",
			component: search
		},
		{
			path: '/answer',
			name:"answer",
			component: answer
		},
		{
			path: '/examinfo',
			name:"examinfo",
			component: examinfo
		},
		{
			path: '/center',
			name:"centerindex",
			component: centerindex
		},
		{
			path: '/center/score',
			name:"centerscore",
			component: centerscore
		},
		{
			path: '/center/member',
			name:"centermemeber",
			component: centermemeber
		},
		
		
		
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router.beforeEach(async (to, from, next) => {
// 	if(to.name == "login"){
// 		next();
// 	}else{
// 		const token = VueCookies.get('userToken');
// 		if (token && token !== 'undefined') {
// 			next()
// 		} else {
// 			// 没有登录的时候跳转到登录界面
// 			// 携带上登陆成功之后需要跳转的页面完整路径
// 			next({
// 				name: 'login'
// 			})
// 		}
// 	}
// })

export default router
