<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="1"></centerLeft>
			<div class="user-info">
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">头像</div>
					<div class="user-info-item-avatar">
						<img src="@/assets/images/login/avatar.png"/>
						<div>点击可更换头像</div>
					</div>
				</div>
				
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">昵称</div>
					<div class="user-info-item-input">
						<el-input placeholder="请输入昵称"></el-input>
					</div>
				</div>
				
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">所在地区</div>
					<div class="user-info-item-input">
						<el-select style="width: 100%;" placeholder="请选择课程体系">
						    <el-option label="课程体系" value="1"></el-option>
						</el-select>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">学校</div>
					<div class="user-info-item-input">
						<el-select style="width: 100%;" placeholder="请选择学校">
						    <el-option label="课程体系" value="1"></el-option>
						</el-select>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">年级</div>
					<div class="user-info-item-input">
						<el-select style="width: 100%;" placeholder="请选择年级">
						    <el-option label="课程体系" value="1"></el-option>
						</el-select>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">课程体系</div>
					<div class="user-info-item-input">
						<el-select style="width: 100%;" placeholder="请选择课程体系">
						    <el-option label="课程体系" value="1"></el-option>
						</el-select>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">角色</div>
					<div class="user-info-item-input">
						<el-select style="width: 100%;" placeholder="请选择角色">
						    <el-option label="角色" value="1"></el-option>
						</el-select>
					</div>
				</div>
				
				<div class="user-info-button">保存</div>
				<div class="user-info-text">完善信息，可获得100积分</div>
			</div>
		</div>
	</div>
</template>

<script>
	import infoTop from "@/components/info-top.vue"
	import centerLeft from "@/components/center-left.vue"
	export default {
		components:{
			infoTop,
			centerLeft
		},
		data(){
			return {
			}
		},
		methods:{
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
</style>