<template>
	<div class="content">
		<indexTop title="试题搜索" :show="false"></indexTop>
		<div v-if="type == 0" class="search flex-center flex-midden">
			<div @click="type = 1" class="search-item">
				<img class="search-item-bg" src="@/assets/images/search/bg1.png"/>
				<div class="search-item-content">
					<div class="search-item-title">拍照搜题</div>
					<div class="search-item-desc">相机拍照搜索</div>
					<img class="search-item-icon" src="@/assets/images/search/righ1.png"/>
				</div>
			</div>
			<div @click="type = 2" class="search-item">
				<img class="search-item-bg" src="@/assets/images/search/bg2.png"/>
				<div class="search-item-content">
					<div class="search-item-title">文字搜题</div>
					<div class="search-item-desc">输入文字搜题</div>
					<img class="search-item-icon" src="@/assets/images/search/righ2.png"/>
				</div>
			</div>
		</div>
		
		<div v-if="type == 1" class="photo-search">
			<div class="photo-search-img">
				<div class="photo-search-img-icon">
					<img class="search-item-bg" src="@/assets/images/search/photo.png"/>
					<div>请选择您的图片</div>
				</div>
			</div>
			<div class="photo-search-title">请注意旋转并竖屏显示题目图片（注红框内可裁剪）</div>
			
			<div class="photo-search-button flex-center flex-midden">
				<div @click="type = 0">取消</div>
				<div>确定</div>
			</div>
		</div>
		
		
		<div v-if="type == 2" class="text-search">
			<textarea class="text-search-text" placeholder="请输入您搜到题目"></textarea>
			
			<div class="photo-search-button flex-center flex-midden">
				<div @click="type = 0">取消</div>
				<div>确定</div>
			</div>
		</div>
		
		<div v-if="type == 3" class="search-result">
			<div class="search-result-title">搜索结果</div>
			<div class="search-result-img"></div>
			<div class="search-result-desc">以下为相似度较高题目，请选择匹配题目</div>
			
			<div class="search-result-tab flex-start flex-center flex-wrap">
				<div class="active">EDXA-AL本土</div>
				<div>EDXA-AL本土</div>
				<div>EDXA-AL本土</div>
				<div>EDXA-AL本土</div>
			</div>
			<div class="search-result-tab flex-start flex-center flex-wrap">
				<div class="active">计算机</div>
				<div>计算机</div>
				<div>计算机</div>
				<div>计算机</div>
			</div>
			
			<div class="search-result-list flex-start flex-center flex-wrap">
				<img v-for="item in 20" @click="showmsg" src="@/assets/images/temp/1.png"/>
			</div>
		</div>
		<msg ref="msg" title="温馨提示" desc="查看本次题目的所有搜索" value="结果要100积分，是否查看"></msg>
		
		
		<div v-if="type == 4" class="search-info">
			<div class="search-info-desc">
				<div class="flex-start flex-center">
					<div><span>考试局</span>EXECEL</div>
					<div><span>科目</span>EXECEL</div>
					<div><span>年份</span>EXECEL</div>
					<div><span>考试季</span>EXECEL</div>
					<div><span>难度</span>EXECEL</div>
				</div>
				<div class="flex-start flex-center">
					<div><span>知识点一</span>XXXXXXXXXXXXXXXX</div>
					<div><span>知识点二</span>XXXXXXXXXXXXXXXX</div>
					<div><span>知识点三</span>XXXXXXXXXXXXXXXX</div>
				</div>
			</div>
			
			<div class="search-info-title">题目</div>
			
			<div class="search-info-content">1.In the following extract from a short story, the narrator describes going to an appointment at alawyer's office in South Africa.
(a) Comment on the language and style of the extract.[15](b) Imagine you are one of the lawyer's other clients and have witnessed the events in the
extract. You decide to write a diary entry reflecting on those eventsBasing your writing closely on the material of the original extract, 
and using 120 to 150 ofyour own words, write a section of your diary entry.
[10]Take it to a lawyer. That's what my friend told me to do. Now, I had never hadoccasion to have anything to do with lawyers. Mention of lawyers always broughtto my mind pictures of courts, police: terrifying pictures. Although I was in trouble,
wondered why it should be a lawyer who would help me. However, my friend gave
me the address.And from that moment my problem loomed larger. It turned in my mind. On the nightbefore my visit to the solicitor, my heart was full of feelings of hurt. My soul fed onfire and scalding water. I'd tell the lawyer; I'd tell him everything that had gnawedinside me for several days.</div>
		
			<div class="flex-start">
				<div class="search-info-title">题目答案</div>
				<img src="@/assets/images/temp/1.png" class="search-info-img"/>
			</div>
			
			<div class="flex-start">
				<div class="search-info-title">视频讲解</div>
				<img src="@/assets/images/temp/1.png" class="search-info-img"/>
			</div>
		</div>
		
		<div v-if="type == 4" class="search-info-button flex-round flex-center">
			<div @click="type = 0">查看原题文档</div>
			<div @click="type = 0">查看原题答案</div>
			<div @click="type = 0">纠错</div>
		</div>
	</div>
</template>

<script>
	import indexTop from "@/components/index-top.vue"
	import msg from "@/components/msg.vue"
	export default {
		components:{
			indexTop,
			msg
		},
		data(){
			return {
				type:0
			}
		},
		methods:{
			showmsg(){
				this.$refs.msg.init();
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
</style>