<template>
	<div class="info">
		<img class="info-close" @click="tolast" src="@/assets/images/close.png"/>
		<div class="info-title">2024年05月10日大一考卷试卷</div>
		<div class="info-content">
			1.In the following extract from a short story, the narrator describes going to an appointment at alawyer's office in South Africa.
			(a) Comment on the language and style of the extract.[15](b) Imagine you are one of the lawyer's other clients and have witnessed the events in the
			extract. You decide to write a diary entry reflecting on those eventsBasing your writing closely on the material of the original extract, 
			and using 120 to 150 ofyour own words, write a section of your diary entry.
			[10]Take it to a lawyer. That's what my friend told me to do. Now, I had never hadoccasion to have anything to do with lawyers. Mention of lawyers always broughtto my mind pictures of courts, police: terrifying pictures. Although I was in trouble,
			wondered why it should be a lawyer who would help me. However, my friend gave
			me the address.
			And from that moment my problem loomed larger. It turned in my mind. On the night
			before my visit to the solicitor, my heart was full of feelings of hurt. My soul fed on
			fire and scalding water. I'd tell the lawyer; I'd tell him everything that had gnawed
			inside me for several days.
		</div>
		
		<div class="info-score">分值 10分</div>
		
		<div class="info-answer flex-start">
			<div class="info-answer-title">学生答案</div>
			<textarea placeholder="请输入答案"></textarea>
		</div>
		
		
		<div class="info-answer flex-start">
			<div class="info-answer-title">学生答案</div>
			<div class="info-answer-list flex-start flex-center">
				<div class="info-answer-list-upload">
					<img src="@/assets/images/search/photo.png"/>
					<div>最多可上传10张</div>
				</div>
			</div>
		</div>
		
		
		<div class="info-answer flex-start">
			<div class="info-answer-title">请选择答案</div>
			<div class="info-answer-select flex-start flex-center">
				<div class="active">A</div>
				<div>B</div>
				<div>C</div>
				<div>D</div>
			</div>
		</div>
		
		<div class="info-button flex-round flex-center">
			<div @click="tolast">保存并返回</div>
			<div @click="tolast">试卷下载</div>
			<div @click="tolast">完成考试</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		data(){
			return {
				ruleForm:{
					account:"",
					password:""
				},
				rules:{
					account: [
						{ required: true, message: '请输入邮箱或手机号', trigger: 'blur' },
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' }
					]
				},
				showpwd:false,//是否展示密码
			}
		},
		methods:{
			tolast(){
				this.$router.push("/");
			},
			toRegister(){
				this.$router.push("/register");
			}
		}
	}
</script>


<style lang="scss" scoped>
	@import "../assets/info.scss";
</style>