<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="4"></centerLeft>
			<img class="memeber-info-bg" src="@/assets/images/me/memeber-bg.png"/>
			<div class="memeber-info">
				<div class="memeber-info-title">充值会员，畅享平台资源</div>
				
				<div class="memeber-info-list flex-start flex-center">
					<div class="memeber-info-item active">
						<div class="memeber-info-item-day">90天</div>
						<div class="memeber-info-item-num"><span>¥</span>10</div>
						<div class="memeber-info-item-copy">¥30</div>
					</div>
					<div class="memeber-info-item">
						<div class="memeber-info-item-day">90天</div>
						<div class="memeber-info-item-num"><span>¥</span>10</div>
						<div class="memeber-info-item-copy">¥30</div>
					</div>
					<div class="memeber-info-item">
						<div class="memeber-info-item-day">90天</div>
						<div class="memeber-info-item-num"><span>¥</span>10</div>
						<div class="memeber-info-item-copy">¥30</div>
					</div>
				</div>
				
				<div class="memeber-info-pay flex-start flex-center">
					<div class="memeber-info-pay-zf flex-between flex-center">
						<img src="@/assets/images/me/pay.png"/>
						<div>支付宝购买</div>
						<div>去购买</div>
					</div>
					<div class="memeber-info-pay-zf flex-between flex-center" style="background-color: rgba(240, 252, 239, 1);">
						<img src="@/assets/images/me/wechat.png"/>
						<div>微信购买</div>
						<div style="background-color: rgba(67, 201, 62, 1);">去购买</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import infoTop from "@/components/info-top.vue"
	import centerLeft from "@/components/center-left.vue"
	export default {
		components:{
			infoTop,
			centerLeft
		},
		data(){
			return {
			}
		},
		methods:{
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
</style>