<template>
	<div class="login">
		<div class="login-login">
			<div class="login-model">
				<img class="login-model-logo" src="@/assets/images/logo.png"/>
				<div class="login-model-text">学生端登录系统</div>
				<el-form v-if="!wechat" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
					<el-form-item prop="account">
						<el-input v-model="ruleForm.account" placeholder="请输入邮箱或手机号"></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.password" placeholder="请输入密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					
					<div class="login-button">
						<div class="forget" @click="toForget">忘记密码</div>
						
						<div class="register" @click="toRegister">注册</div>
					</div>
					<el-form-item>
						<div class="form-button" @click="submitForm('ruleForm')">立即登录</div>
					</el-form-item>
					<div class="wechat" @click="wechat=true">
						<img src="@/assets/images/login/wechat.png"/>
						<div>扫码登录</div>
					</div>
					<div class="agree">
						<img src="@/assets/images/noselect.png"/>
						<div>我已阅读并同意<a @click="showagree">《隐私政策》</a>和<a @click="showagree">《用户协议》</a></div>
					</div>
				</el-form>
				
				<div v-if="wechat">
					<div class="qrcode">
						<img src="@/assets/images/temp/1.png"/>
					</div>
					<div class="form-button" style="margin-top: 36px;" @click="tobind">返回</div>
					
					<div class="agree" style="margin-top: 37px;">
						<img src="@/assets/images/noselect.png"/>
						<div>我已阅读并同意<a @click="showagree">《隐私政策》</a>和<a @click="showagree">《用户协议》</a></div>
					</div>
				</div>
			</div>
		</div>
		<agree ref="agree"></agree>
	</div>
</template>

<script>
	import agree from "@/components/agree.vue"
	export default {
		components:{
			agree
		},
		data(){
			return {
				ruleForm:{
					account:"",
					password:""
				},
				rules:{
					account: [
						{ required: true, message: '请输入邮箱或手机号', trigger: 'blur' },
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' }
					]
				},
				showpwd:false,//是否展示密码
				wechat:false
			}
		},
		methods:{
			showagree(){
				console.log(2);
				this.$refs.agree.init();
			},
			submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
					} else {
						return false;
				  }
				});
			},
			toForget(){
				this.$router.push("/forget");
			},
			toRegister(){
				this.$router.push("/register");
			},
			tobind(){
				this.$router.push("/bindphone");
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login{
		width: 100%;
		height: 100vh;
		background-image: url(../assets/images/login/bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.login-login{
		display: flex;
		justify-content: center;
		align-items: center;
		top: 50%;
		right: 15%;
		position: absolute;
		transform: translate(0%, -50%);
		.login-login-bg{
			position: relative;
			img{
				&:first-of-type{
					width: 600px;
					height: auto;
					margin-bottom: 100px;
					margin-right: 50px;
				}
				&:last-of-type{
					height: 50vh;
					width: auto;
					position: absolute;
					right: 0px;
					top:35%;
				}
			}
		}
	}
	.login-model{
		width: 690px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 62px 0px 37px;
		.login-model-logo{
			width: 251px;
			height: 106px;
			display: block;
			margin: 0 auto;
		}
		.login-model-text{
			font-size: 36px;
			color: #252525;
			line-height: 49px;
			letter-spacing: 2px;
			margin-top: 3px;
			text-align: center;
		}
	}
	.ruleForm{
		margin: 39px auto 0px;
		width: 370px;
		.pwd-see{
			width: 28px;
			height: 28px;
			position: absolute;
			top: 50%;
			margin-top: -14px;
			right: 18px;
			cursor: pointer;
		}
	}
	::v-deep .el-input__inner{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		border: 0px;
	}
	.login-button{
		font-weight: 400;
		font-size: 14px;
		color: #3C4658;
		line-height: 20px;
		margin-top: 13px;
		display: flex;
		justify-content: space-between;
		div{
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			color: #3C4658;
			line-height: 20px;
			text-align: right;
		}
	}
	.form-button{
		width: 370px;
		height: 50px;
		background: #FFB80F;
		border-radius: 6px;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		border: 0px;
		padding: 0px;
		margin: 27px auto 0px;
		cursor: pointer;
	}
	.wechat{
		text-align: center;
		margin-top: 43px;
		cursor: pointer;
		img{
			width: 55px;
			height: 55px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			margin-top: 10px;
		}
	}
	.agree{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 23px;
		cursor: pointer;
		img{
			width: 14px;
			height: 14px;
			margin-right: 8px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			a{
				color: #FFB80F;
			}
		}
	}
	.qrcode{
		width: 247px;
		height: 247px;
		background: #FFF3D9;
		padding: 11px;
		box-sizing: border-box;
		margin: 24px auto 36px;
		img{
			width: 225px;
			height: 225px;
		}
	}
</style>